<template>
  <div>
    <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
      <Search
        codeKey="null"
        :customSearchList="searchType.list"
        :searchObj="searchType.obj"
        @change-search-type="$_changeSearchType"
        @onSearchClick="searchData($event)"
      >
        <template v-slot:before>
          <div class="ui-datepicker-item">
            <DxDateBox
              :styling-mode="stylingMode"
              :width="120"
              v-model="searchType.customTypes.dayStart"
              type="date"
              display-format="yyyy.MM.dd"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              @value-changed="onChangeDayStart"
            >
            </DxDateBox>
          </div>
          <div class="ui-datepicker period">
            <span class="dash">~</span>
          </div>

          <div class="ui-datepicker-item">
            <DxDateBox
              :styling-mode="stylingMode"
              :width="120"
              v-model="searchType.customTypes.dayEnd"
              type="date"
              display-format="yyyy.MM.dd"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              @value-changed="onChangeDayEnd"
            >
            </DxDateBox>
          </div>
          <DxSelectBox
            v-model="searchType.customTypes['result']"
            :items="searchType.resultList"
            display-expr="codeNm"
            value-expr="codeValue"
            :styling-mode="stylingMode"
            :width="120"
            :height="30"
            @value-changed="$_changeSearchCustomType('result', $event)"
          />
        </template>
      </Search>
      <esp-dx-data-grid :data-grid="dataGrid" ref="workHistoryGrid" />
    </div>
    <DxPopup
      :show-title="true"
      :title="modal.title"
      :width="modal.width"
      :height="modal.height"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <ModalWorkDetailVue :jobId="this.modal.jobId" :isOpened="this.modal.isOpened" :rowInfo="this.modal.rowInfo"></ModalWorkDetailVue>
    </DxPopup>
    <DxPopup
      :show-title="true"
      :title="jsonModal.title"
      :width="jsonModal.width"
      :height="jsonModal.height"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      :visible="jsonModal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template>
        <DxTextArea v-model="formatJson" :styling-mode="stylingMode" :height="550"> </DxTextArea>
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="true"
          :options="{
            elementAttr: {
              class: 'default filled txt_S medium',
            },
            text: '확인',
            //type: 'default',
            width: '120',
            height: '40',
            useSubmitBehavior: true,
            onClick: () => {
              isOpenJsonModal(false);
            },
          }"
        />
      </template>
    </DxPopup>
  </div>
</template>
<script>
  import Search from '@/components/common/search.vue';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxButton } from 'devextreme-vue/button';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { isSuccess } from '@/plugins/common-lib';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { EventBus } from '@/event-bus';
  import { DxTextArea } from 'devextreme-vue/text-area';
  import ModalWorkDetailVue from './modal-work-detail.vue';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      DxDateBox,
      DxButton,
      Search,
      DxPopup,
      DxTextArea,
      DxToolbarItem,
      ModalWorkDetailVue,
    },
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
          gradeList: null,
        },
        cmList: [],
        modal: {
          isOpened: false,
          jobId: '',
          rowInfo: {},
          title: '상세보기',
          width: '70%',
          height: '800',
        },
        jsonModal: {
          isOpened: false,
          title: '상세보기',
          width: '60%',
          height: '700',
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          list: [
            {
              codeNm: 'ID',
              codeValue: 'id',
            },
            {
              codeNm: '화면정보',
              codeValue: 'display',
            },
            {
              codeNm: '등록자',
              codeValue: 'regId',
            },
          ],
          resultList: [
            {
              codeNm: '결과',
              codeValue: '0',
            },
            {
              codeNm: '성공',
              codeValue: 'true',
            },
            {
              codeNm: '실패',
              codeValue: 'false',
            },
          ],
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().substr(0, 10),
            dayEnd: new Date(new Date().setDate(new Date().getDate())).toISOString().substr(0, 10),
            regDt: null,
            result: null,
          },

          paramsData: null,
        },
        formatJson: null,
        dataGrid: {
          excel: {
            title: '교환기 이력',
          },
          refName: 'workHistoryGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {
            loading: true,
            DELETE: 'EUC_WORKHISTORY_DELETE',
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: true,
          },
          showActionButtons: {
            excel: true, // 엑셀 버튼
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'Id',
              dataField: 'id',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '교환기',
              dataField: 'cmNm',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateDisplayCellValue: rowData => {
                if (rowData?.cmNm) {
                  return this.getCmName(rowData.cmNm);
                }
                if (rowData.cmNm == null || rowData.cmNm == 'null') {
                  return '';
                }
              },
            },
            {
              caption: '메서드',
              dataField: 'method',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
              fixedPosition: 'left',
              // cellTemplate: (container, options) => {
              // 	const aTag = document.createElement('a');
              // 	aTag.innerText = options.value;
              // 	container.append(aTag);
              // },
            },

            {
              caption: '화면',
              dataField: 'display',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '전문',
              dataField: 'jsondata',
              // width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                let button = new DxButton({
                  propsData: {
                    text: '상세내용',
                    icon: '',
                    type: 'info',
                    height: '25',
                    elementAttr: { class: 'btn_XS default filled' },
                    onClick: () => {
                      this.isOpenJsonModal(true, options.data);
                    },
                  },
                });
                button.$mount();
                container.append(button.$el);
              },
            },
            {
              caption: '결과',
              dataField: 'result',
              width: 100,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '등록자',
              dataField: 'regId',
              width: 100,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '등록일',
              dataField: 'regDt',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: this.formatDt,
            },
            {
              caption: '상세내용',
              dataField: 'detail',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                let button = new DxButton({
                  propsData: {
                    text: '상세내용',
                    icon: '',
                    type: 'info',
                    height: '25',
                    elementAttr: { class: 'btn_XS default filled' },
                    onClick: () => {
                      this.isOpenModal(true, options.data);
                    },
                  },
                });
                button.$mount();
                container.append(button.$el);
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.dataGrid.showActionButtons.customButtons = this.getCustomButtons();
      },
      getCustomButtons() {
        const buttons = [
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: '삭제',
              elementAttr: { class: 'btn_XS white light_filled trash' },
              width: 60,
              height: 30,
              onClick: () => {
                this.onDeleteWorkHistory();
              },
            },
            location: 'before',
          },
        ];

        // buttons.push({
        // 	widget: 'dxButton',
        // 	options: {
        // 		icon: '',
        // 		text: '조회',
        // 		elementAttr: { class: 'btn_XS default filled ' },
        // 		width: 80,
        // 		height: 30,
        // 		onClick: () => {
        // 			this.selectDataList();
        // 		},
        // 	},
        // 	location: 'before',
        // });

        return buttons;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountData() {
        await this.selectCmList();
        await this.selectDataList();
      },
      searchData(paramsData) {
        if (paramsData) {
          this.searchType.paramsData = { ...this.searchType.paramsData, ...paramsData };
        } else {
          const codeValues = this.searchType.list.map(d => d.codeValue);
          if (Object.keys(this.searchType.paramsData).length > 0) {
            Object.keys(this.searchType.paramsData).forEach(key => {
              if (codeValues.includes(key)) {
                delete this.searchType.paramsData[key];
              }
            });
          }
        }
        this.selectDataList();
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList(sort = '-regDt') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);

            if (!params.sort) {
              params.sort = sort;
            }
            params = { ...params, ...this.searchType.paramsData };
            // /**검색조건이 0(전체) 일경우 파라미터 삭제 */
            if (params?.['result'] === '0') {
              delete params['result'];
            }
            const payload = {
              actionname: 'EUC_IPTWORKHISTORY_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /**@description 교환기 이력 삭제 메서드 */
      async onDeleteWorkHistory() {
        const selectedRowsData = this.$refs.workHistoryGrid.selectedRowsData;
        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        } else {
          let params = selectedRowsData.map(data => {
            return { id: data.id };
          });
          const payload = {
            actionname: 'EUC_WORKHISTORY_DELETE',
            data: params,
            loading: true,
          };
          let confirm = '해당 이력을 삭제하시겠습니까?';

          if (await this.$_Confirm(confirm)) {
            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              await this.$_Msg(this.$_msgContents('CMN_SUC_DELETE'));
              await this.selectDataList();
              // await this.$refs.workHistoryGrid.refreshData();
            } else {
              await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
          }
        }
      },
      onChangeDayStart() {
        let regDt =
          this.$_commonlib.moment(this.searchType.customTypes.dayStart).format('YYYYMMDD000000') +
          '~' +
          this.$_commonlib.moment(this.searchType.customTypes.dayEnd).format('YYYYMMDD235959');
        this.searchType.paramsData = { ...this.searchType.paramsData, regDt: regDt };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      onChangeDayEnd() {
        let regDt =
          this.$_commonlib.moment(this.searchType.customTypes.dayStart).format('YYYYMMDD000000') +
          '~' +
          this.$_commonlib.moment(this.searchType.customTypes.dayEnd).format('YYYYMMDD235959');
        this.searchType.paramsData = { ...this.searchType.paramsData, regDt: regDt };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },

      onChangeSearchDate() {
        const dayStart = this.$_commonlib.moment(this.searchType.customTypes.dayStart);
        const dayEnd = this.$_commonlib.moment(this.searchType.customTypes.dayEnd);
        const regDt = `${dayStart.format('YYYYMMDD000000')}~${dayEnd.format('YYYYMMDD235959')}`;
        this.searchType.paramsData = { ...this.searchType.paramsData, regDt };
        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.regDt) {
          return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      async selectCmList(sort = '+cmOrd') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_CM_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.setCmName(res);
        }
      },

      setCmName(res) {
        const length = res.data.data?.length;
        if (length > 0) {
          this.cmList = res.data.data;
          for (let i = 0; i < this.cmList.length; i++) {
            delete this.cmList[i].id;
            delete this.cmList[i].cmIp;
            delete this.cmList[i].passwd;
            delete this.cmList[i].pinPasswd;
            delete this.cmList[i].regDt;
            delete this.cmList[i].siteId;
            delete this.cmList[i].tenantId;
            delete this.cmList[i].userId;
            delete this.cmList[i].vender;
            delete this.cmList[i].viewFl;
          }
        }
      },
      getCmName(cm) {
        let cmNm = '';
        for (let i = 0; i < this.cmList.length; i++) {
          if (this.cmList[i].cmNm === cm) {
            cmNm = this.cmList[i].description;
          }
        }
        if (cmNm !== '' && cmNm !== null) {
          return cmNm;
        } else {
          return '';
        }
      },

      changeJsonFormat(data) {
        let d = JSON.parse(data);
        let jsonString = JSON.stringify(d, null, 4);
        return jsonString;
      },

      /**@description : 팝업 Open/Close */
      isOpenModal(data, rowInfo) {
        if (data) {
          this.modal.jobId = rowInfo.id;
          this.modal.rowInfo = rowInfo;
        }
        this.modal.isOpened = data;
      },
      isOpenJsonModal(data, rowInfo) {
        if (rowInfo) {
          this.formatJson = this.changeJsonFormat(rowInfo.jsondata);
        } else {
          this.formatJson = null;
        }
        this.jsonModal.isOpened = data;
      },
      /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
      setCustomTypes(key, value) {
        this.searchType.customTypes[key] = value;
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      //store에 있는 커스텀 검색 이력 가져오기
      this.$_getSearchHistsCustomType(this.searchType.customTypes);

      let regDt =
        this.$_commonlib.moment(this.searchType.customTypes.dayStart).format('YYYYMMDD000000') +
        '~' +
        this.$_commonlib.moment(this.searchType.customTypes.dayEnd).format('YYYYMMDD235959');
      this.searchType.paramsData = { ...this.searchType.paramsData, regDt: regDt };

      this.$_setSearchHistsCustomType(this.searchType.paramsData);
      this.setCustomTypes('result', '0');
      this.mountData();
    },
    beforeDestroy() {
      EventBus.$off('system-hist:viewContent', this.viewContent);
    },
  };
</script>
